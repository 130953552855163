import { ReactiveList } from "@appbaseio/reactivesearch"
import PropTypes from "prop-types"
import classNames from "classnames"
import React, { useState, useEffect } from "react"
import StandardCard from "../../cards/standard-card/standard-card"
import styles from "./render-selection-items.module.scss"
import Empty from "../../../common/empty/empty"
import util from "../../../../services/util"
import { MdCancel, MdCheckCircle } from "react-icons/md"
import { getGroups, getGroup, updateGroups } from "../../../../services/group"
import mediaService from "../../../../services/media"
import _ from "lodash"
import SelectionCard from "../../cards/selection-card/selection-card"

const RenderSelectionItems = ({
  id,
  activeMedia,
  defaultQueryFn,
  handleRemoveFn,
  formWrappers,
  groupMedia,
  idd,
}) => {
  const [list, setList] = useState([groupMedia])
  const [groups, setGroups] = useState([])
  const [group, setGroup] = useState([])
  const [items, setItemList] = useState([])
  console.log("idd:", idd)


  useEffect(() => {
    getGroup(idd).then(group => {
      setGroup(group)
    })
  }, [])

  const renderSelectionCard = data => {
    const onRemoveClick = (event, item) => {
      event.stopPropagation()
      console.log('Item:', item)
      var array = groupMedia
      console.log("array:", array)
      const newList = _.pull(array, item)
      console.log("newList:", newList)

      const grpList = _.pull(item.groups, idd)

      const UpdatedObj = {
        title: group.title,
        groupDescription: group.groupDescription,
        media: newList,
      }


      const updatedMedia = {
        ...item,
        groups: grpList
      }
      
      mediaService.updateMedia(updatedMedia).then(
        savedMedia => {
          console.log('Saved Media:', savedMedia)
          getGroup(idd).then(groups => {
            console.log('Groups:', groups)
            setGroups(groups)
          })          
        },
        error => {
          console.log('Error:', error)
        }
      )

      console.log("updatedObj:", UpdatedObj)

    }

    const isAddRemoveHidden = () => {
      return formWrappers && formWrappers.length > 0
    }

    return (
      <ul
        id={id ? id : "collectionMediaList"}
        className={styles.thumbnailBatch}
      >
        {_.map(groupMedia, (item, idx) => (
          <li
            key={item._id}
            className={
              activeMedia && mediaData._id === activeMedia._id
                ? styles.active
                : ""
            }
          >
            <MdCancel
              id={(id ? id : "collectionMediaList") + "-" + idx + "-remove"}
              onClick={event => {
                onRemoveClick(event, item)
              }}
              className={classNames({
                [styles.closeIcon]: true,
                [styles.hidden]: isAddRemoveHidden(),
              })}
            />
            <SelectionCard
              key={item._id}
              data={util.fixUrls(item)}
              className={data.length < 4 ? "cardItem" : ""}
              groupDisplay={true}
            />
          </li>
        ))}
      </ul>
    )
  }

  return (
    <>
      <div className="listSelectItems">
        <div className="container">
          <ReactiveList
            defaultQuery={defaultQueryFn}
            className="wrap"
            componentId="SearchResult"
            dataField="name"
            title="ReactiveList"
            stream={false}
            paginationAt="bottom"
            scrollOnChange={false}
            pages={5}
            size={10}
            react={{
              and: {
                and: ["searchKeywords", "searchTitle"],
              },
            }}
            showLoader={false}
            render={({ Loading, error, data }) => {
              if (Loading) {
                return <div>Fetching Result.</div>
              }
              if (error) {
                return (
                  <div>
                    Something went wrong! Error details {JSON.stringify(error)}
                  </div>
                )
              }
              return renderSelectionCard(data)
            }}
            showResultStats={false}
            renderNoResults={() => {
              if (group.media && group.media.length > 0) {
                return null;
              } else {
                return <Empty title="No results found" content="" />;
              }
            }}
          />
        </div>
      </div>
    </>
  )
}

RenderSelectionItems.propTypes = {
  onCardClick: PropTypes.func,
  className: PropTypes.string,
  handleRemoveFn: PropTypes.func,
  id: PropTypes.string,
  activeMedia: PropTypes.object,
}

export default RenderSelectionItems
