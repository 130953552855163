import React from "react"
const LocationContext = React.createContext()
const LocationProvider = ({ location, children }) => {
  return (
    <LocationContext.Provider value={location.state}>
      {children}
    </LocationContext.Provider>
  )
}
export { LocationContext }
export default LocationProvider
