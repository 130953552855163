import React, { useState } from "react"
import PropTypes from "prop-types"
import styles from "./collapsible-list-section.module.scss"
import { MdAddCircle } from "react-icons/md"
import classNames from "classnames"

function CollapsibleListSection({ label, id, className, children }) {
  const [moreDetailsToggle, setMoreDetailsToggle] = useState(false)

  const getChildren = () => {
    if (moreDetailsToggle) {
      return <>{children}</>
    } else {
      return ""
    }
  }

  return (
    <>
      <li>
        <a
          id={id}
          className={classNames(styles.wrapper, className)}
          onClick={e => {
            setMoreDetailsToggle(t => !t)
            e.preventDefault()
          }}
        >
          {label || "More Details"} <MdAddCircle />
        </a>
      </li>
      {getChildren()}
    </>
  )
}

CollapsibleListSection.defaultProps = {
  id: "moreDetails",
}

CollapsibleListSection.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
}

export default CollapsibleListSection
