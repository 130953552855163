import moment from "moment"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import { MdCheckCircle, MdFlag } from "react-icons/md"
import auth from "../../../services/auth"
import moderationService from "../../../services/moderation"
import Button from "../../common/button/button"
import CollapsibleListSection from "../../common/collapsible-list-section/collapsible-list-section"
import GenericMessage from "../../common/generic-message/generic-message"
import ModerateMediaButtons from "../../media-output/sections/moderate-media-buttons/moderate-media-buttons"
import DownloadMediaButton from "../sections/download-media-button/download-media-button"
import MediaKeywords from "../sections/media-keywords/media-keywords"
import styles from "./media-details-view.module.scss"
import util from "../../../services/util"
import modal from "../../../services/modal"
import { Icon, InlineIcon } from "@iconify/react"
import ellipsisH from "@iconify-icons/fa-solid/ellipsis-h"
import { getGroups } from "../../../services/group"
import ConfirmNavLink from "../../common/confirm-nav-link/confirm-nav-link"
import SearchLayout from "../../../components/layout/search-layout/search-layout"
import SearchPageContent from "../../../components/search/search-page-content/search-page-content"
import { useForm } from "react-form"
import ModalWindow from "../../common/modal-window/modal-window"
import ViewGroup from "../view-group/view-group"
import SubmitCorrection from "../submit-correction/index"
import FlagCorrection from "../flag-correction/index"
import { identity } from "lodash"
import constants from "../../../services/constants_fhaa"

const MediaDetailsView = ({
  item,
  setEditStateFn,
  message,
  messageType,
  location,
  groupDisplay,
  ...props
}) => {
  const [_message, setMessage] = useState(message)
  const [_messageType, setMessageType] = useState(messageType)
  const [mediaDeleted, setMediaDeleted] = useState(false)
  const [ellipsis, setEllipsis] = useState([])
  const [ellipsisShow, setEllipsisShow] = useState([])
  const [keywordLimit, setKeywordLimit] = useState(false)
  const [submitCorrectionOpen, setSubmitCorrection] = useState(false)
  const [flagCorrectionOpen, setFlagCorrection] = useState(false)
  const [groupOpen, setGroupOpen] = useState(false)
  const [groupInfo, setGroupInfo] = useState()
  const [group, setGroup] = useState([])
  const [groups, setGroups] = useState({})

  console.log('Location State:', location.state)
  const UploaderEmail = item.uploadedByEmail

  console.log('Item:', item.source_file.size)

  useEffect(() => {
    setMessage(message)
    setMessageType(messageType)
  }, [message, messageType])

  const onEditClick = () => {
    setEditStateFn(true)
  }

  const onDeleteClick = () => {
    modal.setAlertModalOpen(true, {
      title: "This media will be permanently deleted.",
      content: "Do you want to continue?",
      confirm: onDeleteConfirm,
      confirmLabel: "Yes",
    })
  }

  const onDeleteConfirm = () => {
    setEditStateFn(false, null, true).then(deleted => {
      if (deleted) {
        setMediaDeleted(true)
      }
    })
  }

  useEffect(() => {
    const keywordArray = item.keywords.split(",")
    let searchKey = localStorage.getItem("searchkey")
    // keywordArray.sort((x, y) => {
    //   if (x.trim().toLowerCase() === searchKey.toLowerCase()) {
    //     return -1
    //   }
    //   return 1
    // })
    setEllipsisShow(keywordArray.slice(0, 3))
  }, [])

  const getSubmitCorrection = () => {
    const onModalClose = () => {
      setSubmitCorrection(false)
    }
    if (submitCorrectionOpen) {
      return (
        <ModalWindow title="Media Correction" closeHandler={onModalClose}>
          <SubmitCorrection item={item} setModalOpen={setSubmitCorrection} />
        </ModalWindow>
      )
    } else {
      return ""
    }
  }

  const getFlagInappropriate = () => {
    const onModalClose = () => {
      setFlagCorrection(false)
    }
    if (flagCorrectionOpen) {
      return (
        <ModalWindow title="Inappropriate Media" closeHandler={onModalClose}>
          <FlagCorrection item={item} setModalOpen={setFlagCorrection} />
        </ModalWindow>
      )
    } else {
      return ""
    }
  }

  const getGroupWindow = () => {
    const onModalClose = () => {
      setGroupOpen(false)
    }

    if (groupOpen) {
      return (
        <ModalWindow title="Group Media" closeHandler={onModalClose}>
          <ViewGroup gid={groupInfo.id} />
        </ModalWindow>
      )
    } else {
      return ""
    }
  }


  const onClickGroup = (id, type, item) => {
    const info = {
      id: id,
      type: type,
      item: item
    }
    setGroupInfo(info)
    navigate('/view-my-group', { state: { gid: info.id } });
    // setGroupOpen(true)
  }

  const onEllipsisClick = () => {
    setKeywordLimit(!keywordLimit)
  }

  const onSubmitCorrectionClick = item => {
    setSubmitCorrection(true)
  }

  const onFlagInappropriateClick = item => {
    setFlagCorrection(true)
  }

  const getCopyright = () => {
    switch (item.copyright) {
      case "ANYONE":
        return (
          <>
            <MdCheckCircle />
            <span>Anyone can use this media</span>
          </>
        )
      case "ATTRIBUTE":
        return (
          <>
            <MdCheckCircle />
            <span>This media can be used with attribution</span>
          </>
        )
      case "PERSONAL":
        return (
          <>
            <MdCheckCircle />
            <span>This media can be used for personal or academic use</span>
          </>
        )
      default:
        return <div></div>
    }
  }

  const getBranch = () => {
    if (item.branch) {
      let label = item.branch.name

      return (
        <li>
          <span className={styles.attribute}>Branch:</span>
          <span>{label}</span>
        </li>
      )
    } else {
      return ""
    }
  }

  const getGroup = () => {
    if (item.groups) {
      let label = item.groups.title

      return (
        <li>
          <span className={styles.attribute}>Groups:</span>
          <span>{label}</span>
        </li>
      )
    } else {
      return ""
    }
  }

  const getEditedBy = () => {
    if (item.editedBy) {
      let label = item.editedByFull
      if (!label) {
        label = util.parseUserToLabel(item.editedBy)
      }

      return (
        <li>
          <span className={styles.attribute}>Edited By:</span>
          <span>{label}</span>
        </li>
      )
    } else {
      return ""
    }
  }

  const getUploadedBy = () => {
    if (item.canEdit) {
      return (
        <li>
          <span className={styles.attribute}>Contributor / Uploader:</span>
          <span>
            {item.uploadedByFull ? item.uploadedByFull : item.uploadedBy}
          </span>
        </li>
      )
    } else {
      return ""
    }
  }

  const getCreditAuthorPhotographer = () => {
    if (!item.credit && !item.photographer && !item.author) {
      return (
        <li>
          <span className={styles.attribute}>Credit:</span>
          <span>Unknown</span>
        </li>
      )
    } else {
      return (
        <>
          {item.credit ? (
            <li>
              <span className={styles.attribute}>Credit:</span>
              <span>{item.credit}</span>
            </li>
          ) : (
            ""
          )}
          {item.author ? (
            <li>
              <span className={styles.attribute}>Author:</span>
              <span>{item.author}</span>
            </li>
          ) : (
            ""
          )}
          {item.photographer && auth.isLoggedIn() ? (
            <li>
              <span className={styles.attribute}>Photographer:</span>
              <span>{item.photographer}</span>
            </li>
          ) : (
            ""
          )}
        </>
      )
    }
  }

  const getDownloadButton = () => {
    if (item.status === "LIVE") {
      return (
        <DownloadMediaButton
          format="standard"
          mediaId={item.source_file._id}
          name={item.source_file.name}
          className={styles.mainButton}
        />
      )
    }
  }

  const handleModerationProgress = moderationMessage => {
    setMessage(moderationMessage)
    setMessageType("info")
  }

  const handleModerationApproved = (moderationMessage, success) => {
    setMessage(moderationMessage)
    if (success) {
      setMessageType("success")
    } else {
      setMessageType("error")
    }
  }

  const handleModerationRejected = (moderationMessage, success) => {
    setMessage(moderationMessage)
    if (success) {
      setMessageType("success")
      setMediaDeleted(true)
    } else {
      setMessageType("error")
    }
  }

  const getModerationButtons = () => {
    // isModerator only returns true if moderation is enabled and the logged in user has authority to moderate
    if (
      (item.status === "PENDING" || item.status === "DELETED") &&
      auth.isModerator()
    ) {
      return (
        <ModerateMediaButtons
          media={item}
          onModerationProgress={handleModerationProgress}
          onModerationApproved={handleModerationApproved}
          onModerationRejected={handleModerationRejected}
        ></ModerateMediaButtons>
      )
    }
  }

  const getEditButton = () => {
    if (item.canEdit) {
      return (
        <Button
          id="edit-media"
          label="Edit"
          btnStyle="secondary"
          btnSize="small"
          fullWidth
          prependIcon="MdEdit"
          onClick={onEditClick}
        />
      )
    }
  }

  const getDeleteButton = () => {
    if (item.canDelete) {
      return (
        <Button
          id="delete-media"
          label="Delete"
          btnStyle="secondary"
          btnSize="small"
          fullWidth
          prependIcon="MdDelete"
          onClick={onDeleteClick}
        />
      )
    }
  }

  const getActions = () => {
    if (mediaDeleted) {
      return <div className={styles.actions}></div>
    } else {
      return (
        <div className={styles.actions}>
          {getDownloadButton()}
          {getModerationButtons()}
          <div className={styles.subButton}>
            {getEditButton()}
            {getDeleteButton()}
          </div>
        </div>
      )
    }
  }

  const getAdditionalDetails = () => {
    return (
      <CollapsibleListSection>
        <li>
          <span className={styles.attribute}>Province:</span>
          <span>{item.province}</span>
        </li>
        <li>
          <span className={styles.attribute}>Forest:</span>
          <span>{item.forest}</span>
        </li>
        <li>
          <span className={styles.attribute}>Photo Number:</span>
          <span>{item.photoNumber}</span>
        </li>
        <li>
          <span className={styles.attribute}>Source Number:</span>
          <span>{item.sourceNumber}</span>
        </li>
        <li>
          <span className={styles.attribute}>Catalogue Number:</span>
          <span>{item.catalogueNumber}</span>
        </li>
      </CollapsibleListSection>
    )
  }
  const id = item.id

  const ItemGroup = item.groups

  useEffect(() => {
    getGroups().then(groups => {
      setGroup(groups)
    })
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <GenericMessage
          message={_message}
          type={_messageType ? messageType : "success"}
          closeIcon
          setTime
        />
        <div className={styles.details}>
          <div className={styles.detailLeft}>
            <div className={styles.summary}>
              <div className={styles.title}>{item.title}</div>
              <h6>{item.description ? item.description : "No description"}</h6>
            </div>
            <ul className={styles.metadata}>
              <li>
                <span className={styles.attribute}>Vintage:</span>
                <span>
                  {item.photoDate === "18700101"
                    ? ""
                    : moment(item.photoDate, "YYYYMM")
                      .format("MMM, YYYY")
                      .toString() +
                    (item.isDateApproximate ? " (approx)" : "")}
                </span>
              </li>
              <li>
                <span className={styles.attribute}>Size:</span>
                <span>{item.source_file.size ? (item.source_file.size / 1000000).toFixed(2) : 'NA'} Mb</span>
              </li>
              <li>
                <span className={styles.attribute}>Location:</span>
                <span>{item.location}</span>
              </li>
              <li>
                <span className={styles.attribute}>Collection:</span>
                <span>{item.collectionName ? item.collectionName : "N/A"}</span>
              </li>
              {getCreditAuthorPhotographer()}
              {getAdditionalDetails()}
              {getUploadedBy()}
              <li>
                <span className={styles.attribute}>Uploaded Date:</span>
                <span>
                  {moment(item.dateUploaded)
                    .format("MMM DD, YYYY")
                    .toString()}
                </span>
              </li>
              {getBranch()}
              {getEditedBy()}
              <div className={styles.copyright}>{getCopyright()}</div>
            </ul>
          </div>
          <div className={styles.detailRight}>
            <div className={styles.buttonSection}>
              {!groupDisplay && getActions()}
              {/* <div className={styles.copyright}>{getCopyright()}</div> */}
            </div>
            <div className={styles.keywords}>
              <h5>
                Associated Keywords{" "}
                <span>
                  <Icon icon={ellipsisH} onClick={onEllipsisClick} />
                </span>
              </h5>
              <MediaKeywords
                keywords={item.keywords}
                keywordLimit={keywordLimit}
              ></MediaKeywords>
            </div>
          </div>
        </div>

        <div className={styles.correction}>
          <div className={styles.correctBtns}>
            {getSubmitCorrection()}
            <a id="submit-correction" onClick={onSubmitCorrectionClick}>
              <MdCheckCircle />
              Submit Correction
            </a>
            {getFlagInappropriate()}
            <a id="flag-inappropriate" onClick={onFlagInappropriateClick}>
              <MdFlag />
              Flag as Inappropriate
            </a>
          </div>

          {ItemGroup.length > 0 && <h4>Media Groups</h4>}
          <br />
          <div className="group">
            <ul
              className="renderGroup"
              id="render"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {getGroupWindow()}
              {ItemGroup.map(gro => {
                return (
                  <li
                    key={gro.id}
                    style={{
                      marginLeft: "15px",
                      marginBottom: "10px",
                    }}
                  >
                    {/* <ConfirmNavLink to={`/view-my-group?gid=${gro.id}&type=${item.type}&id=${item.id} `}> */}
                    <Button disableStatus={groupDisplay}
                      btnStyle="secondary" label={gro.title}
                      onClick={() => onClickGroup(gro.id, item.type, item.id)}>
                      {gro.title} {/* Assuming you want to display the group's title here */}
                    </Button>
                    {/* </ConfirmNavLink> */}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

MediaDetailsView.propTypes = {
  item: PropTypes.object.isRequired,
  message: PropTypes.string,
  setEditStateFn: PropTypes.func.isRequired,
}

export default MediaDetailsView
