import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import Checkbox from "../../../common/form/check-box/check-box"
import { LocationContext } from "../../../common/location-provider/location-provider"
import asSearchFilter from "../as-search-filter/as-search-filter"
import _ from "lodash"

const SearchCheckboxes = ({
  setValue,
  value,
  options,
  fieldName,
  componentid,
  formInstance,
}) => {
  const state = useContext(LocationContext)
  useEffect(() => handleState(), [state])

  const getSelectedString = () => {
    let str = ""
    _.forEach(options, opt => {
      if (
        formInstance &&
        formInstance.values &&
        formInstance.values[opt.name]
      ) {
        str += opt.name
      }
    })
    return str
  }

  useEffect(() => {
    refreshSelected()
  }, [getSelectedString()])

  const handleState = () => {
    if (!state) {
      return
    }
    let stateField = fieldName
    if (state.useComponentId) {
      if (state.useComponentId) {
        stateField = componentid
      }
    }
    const newValueObj =
      state && state[stateField] ? state[stateField] : { value: [] }
    let valueArray = newValueObj.value
    if (!valueArray) {
      return
    }
    if (!_.isArray(valueArray)) {
      valueArray = valueArray.split(" ")
    }
    _.forEach(valueArray, field => {
      if (field != "") {
        formInstance.setFieldValue(field, oldValue => {
          console.log(
            "Updating '" + field + "' from '" + oldValue + "' to 'true'"
          )
          return true //newValue
        })
      }
    })

    // const queryValue = valueArray.join(" ")
    console.log(
      "handleState search-checkboxes state - query value:",
      valueArray
    )
    setValue(valueArray)
  }

  const handleChange = (e, name) => {
    // handles search field change
    const { checked } = e.target
    console.log("checked: ", checked, ", name: ", name)
    let safeValue = value || []
    let idx = safeValue.indexOf(name)
    if (checked) {
      if (idx === -1) {
        safeValue.push(name)
        setValue(safeValue)
      }
    } else if (!checked) {
      if (idx !== -1) {
        safeValue = safeValue.filter((j, k) => k !== idx)
        setValue(safeValue)
      }
    }

    console.log("New Value: " + safeValue)
    return true
  }

  const refreshSelected = () => {
    const selected = []
    _.forEach(options, opt => {
      if (
        formInstance &&
        formInstance.values &&
        formInstance.values[opt.name]
      ) {
        selected.push(opt.name)
      }
    })
    console.log("refreshed search checkboxes: " + selected)
    setValue(selected)
  }

  return (
    <ul>
      {options.map(option => (
        <li key={option.name}>
          <Checkbox
            field={option.name}
            label={option.label}
            // TODO setting this checked value throws errors but also is the only way I have been able to toggle the check when navigating back to the
            // search page so far. Should find the proper way of doing it (like updating via formInstance? That hasn't been working though...)
            checked={(value || []).indexOf(option.name) !== -1}
            onChange={e => handleChange(e, option.name)}
          />
        </li>
      ))}
    </ul>
  )
}

SearchCheckboxes.propTypes = {
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  queryType: PropTypes.string,
}

export default asSearchFilter(SearchCheckboxes)
