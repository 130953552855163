import React, { useState } from "react"
import keyword from "../../../services/keyword"
import people from "../../../services/people"
import mediaService from "../../../services/media"
import MediaDetailsEdit from "../media-details-edit/media-details-edit"
import MediaDetailsView from "../media-details-view/media-details-view"
import _ from "lodash"

const ViewMedia = ({ item, location, groupDisplay, ...props }) => {

  console.log('Props:', location)

  const [editState, setEditState] = useState(false)
  const [itemTags, setItemTags] = useState([])
  const [peopleTags, setPeopleTags] = useState([])
  const [media, setMedia] = useState()
  const [groups, setGroups] = useState(item.groups)
  const [message, setMessage] = useState(null)
  const [messageType, setMessageType] = useState("success")

  const handleEditRequestError = (error, resolve) => {
    setErrorMessage("An error has occurred")
    console.log("view-media: ERROR", error)
    resolve(false)
  }

  const setErrorMessage = message => {
    setMessage(message)
    setMessageType("error")
  }
  const setSuccessMessage = message => {
    setMessage(message)
    setMessageType("success")
  }

  const setInfoMessage = message => {
    setMessage(message)
    setMessageType("info")
  }

  // This should probably be split into two or more functions
  const toggleEditing = (editState, modifiedItem, itemDeleted) => {
    return new Promise(resolve => {
      if (editState) {
        setInfoMessage("Please wait...")
        console.log('Item Keywords:', item.keywords)
        keyword.findByTerms(item.keywords).then(
          keywordObjects => {
            setSuccessMessage(null)
            setItemTags(keyword.parseKeywordsForAutoComplete(keywordObjects))
            setEditState(true)
            resolve(true)
          },
          error => {
            setErrorMessage("An error has occurred setting up edit")
            console.log("view-media keyword query: ERROR", error)
            resolve(false)
          }
        )

        let peopleTagString = ''
        let peopleTagArray = []
        if (media) {
          peopleTagString =  media.peopleTags
        }   
        else {
          if (_.isArray(item.peopleTags)) {
            console.log('Array')
            _.map(item.peopleTags, (tag) => {
              peopleTagArray.push(tag.value)
            })
            peopleTagString = peopleTagArray.join(",")
          }
          else {          
            peopleTagString =  item.peopleTags
          }  
        }


        people.findByNames(peopleTagString).then(
          peopleObjects => {
            setSuccessMessage(null)
            setPeopleTags(people.parsePeopleForAutoComplete(peopleObjects))
            setEditState(true)
            resolve(true)
          },
          error => {
            setErrorMessage("An error has occurred setting up edit")
            console.log("view-media keyword query: ERROR", error)
            resolve(false)
          }
        )
      } else {
        if (itemDeleted) {
          setInfoMessage("Deleting Media...")
          mediaService.deleteMedia(item).then(
            deletedMedia => {
              setSuccessMessage("Media deleted successfully")
              // return to search results? Display message saying media has been deleted?
              setEditState(false)
              resolve(true)
            },
            error => {
              handleEditRequestError(error, resolve)
            }
          )
        } else if (modifiedItem) {
          setInfoMessage("Updating Media...")
          mediaService.updateMedia(modifiedItem).then(
            savedMedia => {
              setSuccessMessage("Media updated successfully")
              // refresh the page? Probably enough to just extend item with the savedMedia
              _.extend(item, savedMedia)

              setMedia(savedMedia)
              console.log('Item', item)  

              setGroups(savedMedia.groups)
              setEditState(false)
              resolve(true)
            },
            error => {
              handleEditRequestError(error, resolve)
            }
          )
        } else {
          // user cancelled
          setSuccessMessage(null)
          setEditState(false)
          resolve(true)
        }
      }
    })
  }

  const getDetailsContent = () => {
   
    if (editState === false) {
      return (
        <MediaDetailsView
          message={message}
          messageType={messageType}
          item={item}
          setEditStateFn={toggleEditing}
          location={location}
          groupDisplay={groupDisplay}
        ></MediaDetailsView>
      )
    } else if (editState === true) {
      return (
        <MediaDetailsEdit
          message={message}
          messageType={messageType}
          item={item}
          itemTags={itemTags}
          itemPeopleTags={peopleTags}
          itemGroups={groups}
          setEditStateFn={toggleEditing}
        ></MediaDetailsEdit>
      )
    }
  }

  return getDetailsContent()
}

export default ViewMedia
