import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import auth from "../../../services/auth"
import { getGroup } from "../../../services/group"
import mediaService from "../../../services/media"
import { getMedia } from "../../../services/media"
import storyService from "../../../services/story"
import RenderGroupItems from "../../media-output/lists/render-group-items/render-group-items"

const RenderMedia = ({
  attachedMedia,
  onMediaAttached,
  mediaType,
  group,
  groupData,
  id,
}) => {
  const [internalMedia, setInternalMedia] = useState(attachedMedia)
  const [media, setMedia] = useState([])

  const cardClickFn = data => {
    if (mediaType === "Photo") {
      mediaService.getMedia(data.id).then(result => {
        setInternalMedia(result)
        onMediaAttached(result)
      })
    } else {
      storyService.getStory(data.id).then(result => {
        setInternalMedia(result)
        onMediaAttached(result)
      })
    }
  }

  const clearInternalMedia = () => {
    setInternalMedia(null)
    onMediaAttached(null)
  }

  const listMediaFn = () => {
    const authObj = auth.getAuth()
    return {
      query: {
        bool: {
          must: [
            {
              match: {
                grp: {
                  query: group,
                  operator: "and",
                },
              },
            },
            // {
            //   match: {
            //     branch: {
            //       query: authObj.user.branch.id,
            //       operator: "and",
            //     },
            //   },
            // },
            // {
            //   match: {
            //     grp: {
            //       query: group,
            //       operator: "and",
            //     },
            //   },
            // },
          ],
        },
      },
    }
  }

  const getSearchMedia = () => {
    // return the search form
    // this markup is excessive. Just copying the existing stuff for the rest of the form

    return (
      <div>
        <div>
          <RenderGroupItems
            defaultQueryFn={listMediaFn}
            onCardClick={cardClickFn}
            groupData={groupData}
            medias={media}
            id={id}
          />
        </div>
      </div>
    )
  }

  return <>{getSearchMedia()}</>
}

RenderMedia.defaultProps = {
  allowedMediaTypes: ["Photo"],
}

RenderMedia.propTypes = {
  attachedMedia: PropTypes.array,
  onMediaAttached: PropTypes.func.isRequired,
}

export default RenderMedia
