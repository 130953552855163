import React, { useContext, useEffect } from "react"
import useDebounceState from "../../../../hooks/use-debounce-state"
import TextField from "../../../common/form/text-field/text-field"
import { LocationContext } from "../../../common/location-provider/location-provider"
import asSearchFilter from "../as-search-filter/as-search-filter"

const SearchText = asSearchFilter(
  ({
    placeholder,
    fieldName,  
    multiFieldNames,
    setValue,
    setQuery,
    value,
    componentid,
    formInstance,
    queryType,
    ...rest
  }) => {
    const state = useContext(LocationContext)

    const [curText, setCurText] = useDebounceState(value, setValue)
    useEffect(() => handleState(), [state])
    useEffect(() => setValue(formInstance.values[fieldName]), [
      formInstance.values
        ? formInstance.values[fieldName]
        : formInstance.values,
    ])

    const handleState = () => {
      if (!state) {
        return
      }
      let stateField = fieldName
      if (state.useComponentId) {
        if (state.useComponentId) {
          stateField = componentid
        }
      }
      const newValueObj =
        state && state[stateField] ? state[stateField] : { value: "" }
      let newValue = newValueObj.value
      if (!newValue) {
        return
      }

      console.log("handleState search-text state - query value:", newValue)
      setCurText(newValue)
      // formInstance.setFieldValue(fieldName, oldValue => {
      //   console.log(
      //     "Updating '" + fieldName + "' from '" + oldValue + "' to 'true'"
      //   )
      //   return newValue //newValue
      // })

      // const queryValue = valueArray.join(" ")
      // setValue(newValue)
    }

    return (
      <TextField
        field={fieldName}
        value={curText || ""}
        onChange={e => setCurText(e.target.value)}
        placeholder={placeholder || "Search"}
        {...rest}
      />
    )
  }
)

export default SearchText
