import axios from "axios"
import util from "./util"

// const baseUrl = `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/`

// const baseUrl = process.env.GATSBY_FEDIA_DMS_PORT && process.env.GATSBY_FEDIA_DMS_PORT !== '80' && process.env.GATSBY_FEDIA_DMS_PORT !== 80 ? 
//   `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/` :
//   `http://${process.env.GATSBY_FEDIA_DMS_HOST}/`;

//   const fixUrls = media => {
//     return {
//       ...media,
//       webViewUrl: media.webViewUrl ? `${baseUrl}${media.webViewUrl.replace('http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/','')}` : media.webViewUrl,
//       thumbnailUrl: media.thumbnailUrl ? `${baseUrl}${media.thumbnailUrl.replace('http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/','')}` : media.thumbnailUrl
//     };
//   }


export const getStory = async id => {
  return new Promise(function(resolve, reject) {
    axios.get("/stories/" + id).then(
      response => {
        console.log("story queried successfully", response)
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("getStory ERROR: story not found", error)
        reject(error)
      }
    )
  })
}

export const findFeaturedStories = async branch => {
  return new Promise(function(resolve, reject) {
    axios.get("/findFeaturedStories/" + branch).then(
      response => {
        console.log("featured stories queried successfully", response)
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("findFeaturedStories ERROR: story not found", error)
        reject(error)
      }
    )
  })
}

export const saveStory = async story => {
  var id = story._id ? story._id : story.id
  if (id) {
    return updateStory(story)
  } else {
    return createStory(story)
  }
}

/**
 *
 * @param {*} story a story object to be created
 */
export const createStory = async story => {
  return new Promise(function(resolve, reject) {
    axios.post("/stories", story).then(
      response => {
        console.log("story created successfully", response)

        var updatedStory = response.data
        updatedStory.canEdit = true
        updatedStory.canDelete = true

        resolve(util.fixUrls(updatedStory))
      },
      error => {
        console.log("createStory error:", error)
        reject(error)
      }
    )
  })
}

/**
 *
 * @param {*} story a story object to be updated
 */
export const updateStory = async story => {
  return new Promise(function(resolve, reject) {
    var id = story._id ? story._id : story.id
    axios.put("/stories/" + id, story).then(
      response => {
        console.log("story " + id + " updated successfully", response)
        // response.data is the updated story object
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("updateStory error:", error)
        reject(error)
      }
    )
  })
}

export const deleteStory = async story => {
  var id = _.isString(story) ? story : story._id ? story._id : story.id
  return new Promise(function(resolve, reject) {
    axios.delete("/stories/" + id).then(
      response => {
        console.log("story " + story._id + " deleted successfully", response)
        // pass back the deleted story?
        resolve(story)
      },
      error => {
        console.log("deleteStory error:", error)
        reject(error)
      }
    )
  })
}

export const publishStory = async story => {
  return new Promise(function(resolve, reject) {
    var id = story._id ? story._id : story.id
    var promise
    if (id) {
      promise = axios.put("/story/" + id + "/publish", story)
    } else {
      promise = axios.post("stories/publish", story)
    }
    promise.then(
      response => {
        if (id) {
          console.log("story " + id + " published successfully", response)
        } else {
          console.log("New story published successfully", response)
        }
        var updatedStory = response.data
        updatedStory.canEdit = true
        updatedStory.canDelete = true

        resolve(util.fixUrls(updatedStory))
      },
      error => {
        console.log("updateStory error:", error)
        reject(error)
      }
    )
  })
}

export const saveAndPublishStory = async story => {
  return new Promise(function(resolve, reject) {
    var id = story._id ? story._id : story.id
    axios.put("/story/" + id + "/publish", story).then(
      response => {
        console.log("story " + id + " updated successfully", response)
        // response.data is the updated story object
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("updateStory error:", error)
        reject(error)
      }
    )
  })
}

export default {
  getStory,
  saveStory,
  createStory,
  updateStory,
  deleteStory,
  publishStory,
  saveAndPublishStory,
  findFeaturedStories,
}
