import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-form"
import Menu from "../menu/menu"
import "./search-header.scss"
import SearchAutocomplete from "../../search/filters/search-autocomplete/search-autocomplete"
import { DataSearch } from "@appbaseio/reactivesearch"
import SearchText from "../../search/filters/search-text/search-text"

const SearchHeader = ({ siteTitle }) => {
  var defaultSearchParamsObj = {
    multi: null
  }

  const defaultValues = React.useMemo(() => defaultSearchParamsObj, [])
  const formInstance = useForm({
    defaultValues: defaultValues,
  })
  const { Form } = formInstance
  
  return (
    <header className="search-header">
      <div className="app-header-logo">
        <Link to="/">{siteTitle}</Link>
      </div>
      <Form className="searchbox">
        {/* keyword search */}
        {/* <SearchAutocomplete
          componentId="searchKeywords"
          index="keyword"
          label=""
          placeholder="Search"
          fieldName="title,keywords,description"
          dataField="term"
          clearOnSelect={true}
          filterLabel="for "
          allowMultiple={true}
        /> */}

        {/* Multi search using keyword suggestions */}
        <SearchAutocomplete
          componentId="searchMulti"
          index="keyword"
          label=""
          placeholder="Search"
          fieldName="multi"
          multiFieldNames="title,keywords,description"
          dataField="term"
          strictSelection={false}
          // clearOnSelect={true}
          // filterLabel="for "
          // allowMultiple={true}
        />

        {/* Multi search with no suggestions */}
        {/* <SearchText
          componentId="searchMulti"
          type="text"
          placeholder="Search"
          fieldName="multi"
          multiFieldNames="title,keywords,description"
          prependIcon="MdSearch"
          showFilter={false}
          formInstance={formInstance}
          hideLabel={true}
        /> */}
      </Form>
      <div className="menu">
        <Menu />
      </div>
    </header>
  )
}

SearchHeader.propTypes = {
  siteTitle: PropTypes.string,
}

SearchHeader.defaultProps = {
  siteTitle: ``,
}

export default SearchHeader
