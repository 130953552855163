import React from "react"
import styles from "./empty.module.scss"
import PropTypes from "prop-types"

const Empty = ({ title, content }) => {
  return (
    <div className={styles.empty}>
      <div className={styles.tree}></div>
      {title ? <h2 className={styles.title}>{title}</h2> : ""}
      {content ? <p className={styles.content}>{content}</p> : ""}
    </div>
  )
}

Empty.defaultProps = {
  title: "No results found",
  content: "It seems we can’t find any results based on your search.",
}
Empty.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}
export default Empty
