import PropTypes from "prop-types"
import React, { useState } from "react"
import auth from "../../../../services/auth"
import modal from "../../../../services/modal"
import moderation from "../../../../services/moderation"
import Button from "../../../common/button/button"
import styles from "./moderate-media-buttons.module.scss"

const ModerateMediaButtons = ({
  media,
  getDuplicates,
  onModerationApproved,
  onModerationRejected,
  onModerationProgress,
}) => {
  const [message, setMessage] = useState()

  const onApproveClick = event => {
    event.stopPropagation()
    if (onModerationProgress) {
      onModerationApproved("Approving Media...", true)
    } else {
      setMessage("Approving Media...")
    }

    console.log("Approving Media: " + media._id)
    moderation.approve([media], getDuplicates).then(
      () => {
        media.status = "LIVE"
        if (onModerationApproved) {
          onModerationApproved("Media Approved", true)
        } else {
          setMessage("Media Approved")
        }
      },
      error => {
        if (onModerationApproved) {
          onModerationApproved(error.toString(), false)
        } else {
          setMessage(error.toString())
        }
      }
    )
  }

  const onModalConfirm = () => {
    if (onModerationProgress) {
      onModerationApproved("Rejecting Media...", true)
    } else {
      setMessage("Rejecting Media...")
    }
    console.log("Rejecting Media: " + media._id)

    moderation.reject([media]).then(
      response => {
        // this is a fake status. Just set so this component disables the moderate buttons
        media.status = "DELETED"
        if (onModerationRejected) {
          onModerationRejected("Media Rejected", true)
        } else {
          setMessage("Media Rejected")
        }
      },
      error => {
        if (onModerationRejected) {
          onModerationRejected(error.toString(), false)
        } else {
          setMessage(error.toString())
        }
      }
    )
  }

  const onReject = event => {
    event.stopPropagation()

    modal.setAlertModalOpen(true, {
      title: "This media will be permanently deleted.",
      content: "Do you want to continue?",
      confirm: onModalConfirm,
      confirmLabel: "Yes",
    })
  }

  const getButtonMarkUp = () => {
    return (
      <>
        { auth.isBranchOrOrgModerating() ? (
          <Button
            label={getDuplicates ? "Keep" : "Approve"}
            btnSize="small"
            btnStyle="primary"
            disableStatus={media.status === "DELETED"}
            id={"approve-media" + (media._id ? "-" + media._id : "")}
            onClick={onApproveClick}
          />
        ) : (
          ""
        )}
        <Button
          label={getDuplicates ? "Remove" : "Reject"}
          btnSize="small"
          btnStyle="secondary"
          disableStatus={media.status === "DELETED"}
          id={"delete-media" + (media._id ? "-" + media._id : "")}
          onClick={onReject}
        />
      </>
    )
  }

  return (
    <div className={styles.wrapper}>
      {/* This message will only ever show if there is no custom handling passed in by the implementing component (e.g. media-details-view implements this and passes in custom handling) */}
      {message ? (
        <div className={styles.responseMessage}>{message}</div>
      ) : (
        getButtonMarkUp()
      )}
    </div>
  )
}

ModerateMediaButtons.defaultProps = {}

ModerateMediaButtons.propTypes = {
  media: PropTypes.object.isRequired,
  onModerationProgress: PropTypes.func,
  onModerationApproved: PropTypes.func,
  onModerationRejected: PropTypes.func,
}

export default ModerateMediaButtons
