import React, { useState } from "react"
import { navigate } from "gatsby"
import { MdKeyboardArrowLeft } from "react-icons/md"
import moment from "moment"
import keyword from "../../../services/keyword"
import storyService from "../../../services/story"
import util from "../../../services/util"
import StoryDetailsEdit from "../story-details-edit/story-details-edit"
import StoryDetailsView from "../story-details-view/story-details-view"
import Layout from "../../layout/layout/layout"
import InnerLayout from "../../layout/inner-layout/inner-layout"
// import StoryDisplayPreview from "../story-display-preview/story-display-preview"
import styles from "./view-story.module.scss"
const ViewStory = ({ item, isCreate, children, location, groupDisplay, ...props }) => {
  const [editState, setEditState] = useState(isCreate ? true : false)
  const [itemTags, setItemTags] = useState([])
  const [message, setMessage] = useState(null)
  const [messageType, setMessageType] = useState("success")

  const fixUrl = webViewUrl => {
    let baseUrl = ''
    if (webViewUrl.includes('http://')) {
      baseUrl = util.baseUrl()
    }
    else {
      baseUrl = util.baseUrl().replace('http://', '')
    }

    return webViewUrl ? `${util.baseUrl()}${webViewUrl.replace(baseUrl, '').toLowerCase().replace('http://appst-fedia-pq7u6wd4330r-317694051.ca-central-1.elb.amazonaws.com/','')}` : webViewUrl;
  }

  const handleEditRequestError = (error, resolve) => {
    setErrorMessage("An error has occurred")
    console.log("view-story: ERROR", error)
    resolve(false)
  }

  const setErrorMessage = message => {
    setMessage(message)
    setMessageType("error")
  }
  const setSuccessMessage = message => {
    setMessage(message)
    setMessageType("success")
  }

  const setInfoMessage = message => {
    setMessage(message)
    setMessageType("info")
  }
  // This should probably be split into two or more functions
  const toggleEditing = (
    editState,
    modifiedItem,
    itemDeleted,
    itemPublished
  ) => {
    return new Promise(resolve => {
      if (editState) {
        setInfoMessage("Please wait...")
        keyword.findByTerms(item.keywords).then(
          keywordObjects => {
            setSuccessMessage(null)
            setItemTags(keyword.parseKeywordsForAutoComplete(keywordObjects))
            setEditState(true)
            resolve(true)
          },
          (error) => {
            setErrorMessage("An error has occurred setting up edit")
            console.log("view-story keyword query: ERROR", error)
            resolve(false)
          }
        )
      } else {
        if (itemDeleted) {
          setInfoMessage("Deleting Story...")
          storyService.deleteStory(item).then(
            deletedStory => {
              setSuccessMessage("Story deleted successfully")
              // return to search results? Display message saying story has been deleted?
              setEditState(false)
              resolve(true)
            },
            error => {
              handleEditRequestError(error, resolve)
            }
          )
        } else if (modifiedItem && !itemPublished) {
          setInfoMessage("Saving Story...")
          storyService.saveStory(modifiedItem).then(
            savedStory => {
              setSuccessMessage("Story updated successfully")
              // refresh the page? Probably enough to just extend item with the savedStory
              _.extend(item, savedStory)
              setEditState(false)
              resolve(true)
            },
            error => {
              handleEditRequestError(error, resolve)
            }
          )
        } else if (itemPublished) {
          setInfoMessage("Publishing Story...")
          console.log('Modified Item:', modifiedItem)
          storyService.publishStory(modifiedItem ? modifiedItem : item).then(
            savedStory => {
              if (modifiedItem) {
                setSuccessMessage("Story saved and published successfully")
              } else {
                setSuccessMessage("Story published successfully")
              }

              // refresh the page? Probably enough to just extend item with the publishedStory
              _.extend(item, savedStory)
              setEditState(false)
              resolve(true)
            },
            error => {
              handleEditRequestError(error, resolve)
            }
          )
        } else {
          // user cancelled
          setSuccessMessage(null)
          setEditState(false)
          resolve(true)
        }
      }
    })
  }

  const onBackClick = async () => {

    console.log('Location:', location)
    if (location.state && location.state.path) {
      const _searchState =
        // when we have state information we can use it to assist navigation (for preserving previous searches)
        navigate(location.state.path, {
          state: _.extend({ useComponentId: true }, location.state.searchState),
        })
    } else {
      await navigate("/")
    }
  }  

  const getHeaderHeroImageWrapper = () => {
    console.log('Item Media', item.media)
    if (item && item.media && item.media.length > 0) {  
      const innerStoryHeader = (
        <>
          <div className={styles.info}>
            <h2 className={styles.title}>{item.title}</h2>
            <p className={styles.subtitle}>
              {moment(item.media[0].createdAt)
                .format("MMM DD, YYYY")
                .toString()}
              <span> | </span> By{" "}
              {item.media[0].uploadedByUsername
                ? item.media[0].uploadedByUsername
                : item.media[0].uploadedBy}
            </p>
          </div>
          <span className={styles.mask}></span>
        </>
      )

      console.log('Url:', fixUrl(item.media[0].webViewUrl))
      let url =  fixUrl(item.media[0].webViewUrl)
      if (process.env.GATSBY_FEDIA_DMS_HOST === 'api.fedia-test.com' || process.env.GATSBY_FEDIA_DMS_HOST === 'api.fedia-api.com') {
        url = url.replace('localhost:1337/', '')
      }  
      const lastIndex = url.lastIndexOf('/')
      url = url.substring(0, lastIndex + 2)

      return (
        <div
          className={styles.heroImage}
          style={{
            backgroundImage: `url(${url})`,
          }}
        >
          {innerStoryHeader}
        </div>
      )
    } else {
      return <div></div>
    }
  }

  const getDetailsContent = () => {
    if (editState === false) {
      if (isCreate) {
          return (
            <div key={item._id} className={styles.wrapper}>
              <div className={styles.mainContent}>
                  <Layout>
                      <div className={styles.storyWrap}>
                        <div className={styles.container}>
                          <div className={styles.back}>
                            <a id="return-to-list" onClick={onBackClick}>
                              <MdKeyboardArrowLeft /> Go Back
                            </a>
                          </div>
                        </div>
                        {getHeaderHeroImageWrapper()}
                      </div>
                    {children}
                    <StoryDetailsView
                      message={message}
                      messageType={messageType}
                      item={item}
                      setEditStateFn={toggleEditing}
                      groupDisplay={groupDisplay}
                    ></StoryDetailsView>
                  </Layout>
              </div>
            </div>
          )
        }
        else {
          return (
            <div key={item._id} className={styles.wrapper}>
              <div className={styles.mainContent}>
                <StoryDetailsView
                  message={message}
                  messageType={messageType}
                  item={item}
                  modal={true}
                  setEditStateFn={toggleEditing}
                  groupDisplay={groupDisplay}
                ></StoryDetailsView>
              </div>
            </div>
          )
        }  
    } else if (editState === true) {
        if (isCreate) {       
          return (
            <InnerLayout>
              <StoryDetailsEdit
                message={message}
                messageType={messageType}
                item={item}
                itemTags={itemTags}
                setEditStateFn={toggleEditing}
              ></StoryDetailsEdit>
            </InnerLayout>
          )
        }
        else {
          return (
            <StoryDetailsEdit
              message={message}
              messageType={messageType}
              item={item}
              itemTags={itemTags}
              setEditStateFn={toggleEditing}
            ></StoryDetailsEdit>
          )          
        }    
    }
  }

  return getDetailsContent()
}

export default ViewStory
