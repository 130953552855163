import React, { useContext, useEffect, useState } from "react"
import DateRange from "../../../common/form/daterange/daterange"
import { LocationContext } from "../../../common/location-provider/location-provider"
import asSearchFilter from "../as-search-filter/as-search-filter"
import "./search-daterange.scss"

const SearchDateRange = asSearchFilter(
  ({ setValue, fieldName, componentid, formInstance }) => {
    const state = useContext(LocationContext)
    useEffect(() => handleState(), [state])
    useEffect(() => setValue(formInstance.values[fieldName]), [
      formInstance.values
        ? formInstance.values[fieldName]
        : formInstance.values,
    ])

    const handleState = () => {
      if (!state) {
        return
      }
      let stateField = fieldName
      if (state.useComponentId) {
        if (state.useComponentId) {
          stateField = componentid
        }
      }

      const newValueObj =
        state && state[stateField] ? state[stateField] : { value: null }
      let newValue = newValueObj.value ? newValueObj.value : newValueObj;
      if (newValue && (newValue.startDate || newValue.endDate)) {
        console.log("handleState search-text state - query value:", newValue)
        formInstance.values[fieldName] = newValue;
        setValue(newValue)
      } else {
        setValue(formInstance.values[fieldName])
        return
      }
      formInstance.setFieldValue(fieldName, oldValue => {
        console.log(
          "Updating '" + fieldName + "' from '" + oldValue + "' to 'true'"
        )
        return newValue
      })

      // const queryValue = valueArray.join(" ")
    }

    const onDateChange = value => {
      setValue(value)
    }

    return <DateRange field={fieldName} onChange={onDateChange} />
  }
)

export default SearchDateRange
