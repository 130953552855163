import { navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import axios from "axios"
import classNames from "classnames"
import styles from "./media-keywords.module.scss"
import formData from "form-data"
import constants from "../../../../services/constants_fhaa"

const MediaKeywords = ({ keywords, mediaId, format, onSelectKeyword, keywordLimit }) => {
  const performKeywordSearch = async keyword => {
    await axios.get("/keywords/findByTerm/" + keyword).then(response => {
      const k = response.data.keyword
      if (k) {
        navigate(constants.searchPath, { state: { useComponentId: true, searchMulti: keyword } })
        // navigate(constants.searchPath, {
        //   state: { keywords: [{ value: k.term, source: { _id: k._id } }] },
        // })
      } else {
        console.log("ERROR: No keyword found")
      }
    })
  }

  var displayedKeywords = keywords.split(",")
  if (displayedKeywords.length > 3 && keywordLimit) {
    displayedKeywords = displayedKeywords.slice(0, 3)
  }

  return (
    <ul
      format={format}
      className={classNames(
        styles.wrapper,
        format === "compact" ? styles.compact : styles.standard
      )}
    >
      {displayedKeywords.map(keyword => (
        <li
          key={(mediaId ? mediaId + "-" : "") + keyword + "-keyword"}
          id={(mediaId ? mediaId + "-" : "") + keyword + "-keyword"}
          onClick={async e => {
            // don't navigat to view the item
            e.stopPropagation();
            if (onSelectKeyword) {
              onSelectKeyword(keyword)
            } else {
              console.log("searching for media by keyword: " + keyword)
              performKeywordSearch(keyword)
            }
          }}
        >
          {keyword}
        </li>
      ))}
    </ul>
  )
}

MediaKeywords.defaultProps = {
  format: "standard",
}

MediaKeywords.propTypes = {
  keywords: PropTypes.string.isRequired,
  mediaId: PropTypes.string, // needed to create unique ids for testing on list page
  format: PropTypes.string, // "standard" for detail view & "compact" for list view
  onSelectKeyword: PropTypes.func,
}

export default MediaKeywords
