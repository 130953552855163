import PropTypes from "prop-types"
import React, { useState } from "react"
import auth from "../../../services/auth"
import mediaService from "../../../services/media"
import storyService from "../../../services/story"
import ListSelectionItems from "../../media-output/lists/list-selection-items/list-selection-items"
import FilterFiles from "../../search/filter-files/filter-files"
import SearchWrapper from "../search-wrapper/search-wrapper"
import styles from "./attach-media.module.scss"

const AttachMedia = ({ attachedMedia, onMediaAttached, allowedMediaTypes, mediaType }) => {
  const [internalMedia, setInternalMedia] = useState(attachedMedia)

  const cardClickFn = data => {
    if (mediaType === 'Photo') {
      mediaService.getMedia(data.id).then(result => {
        setInternalMedia(result)
        onMediaAttached(result)
      })
    }  
    else {
      storyService.getStory(data.id).then(result => {
        setInternalMedia(result)
        onMediaAttached(result)
      })      
    }  
  }

  const clearInternalMedia = () => {
    setInternalMedia(null)
    onMediaAttached(null)
  }

  const listMediaFn = () => {
    const authObj = auth.getAuth()
    return {
      query: {
        bool: {
          must: [
            {
              match: {
                type: {
                  query: mediaType,
                  operator: 'and'
                }
              }
            },
            {
              match: {
                branch: {
                  query: authObj.user.branch.id,
                  operator: 'and'
                }
              }
            }
          ]
        }
      }
    }
  }

  const getSearchMedia = () => {
    // return the search form
    // this markup is excessive. Just copying the existing stuff for the rest of the form

    return (
      <div className={styles.wrapper}>
        {/* <CollapsibleListSection label={"Attach Media"}> */}
        <SearchWrapper index="media" searchPath={"search"}>
          <div className={styles.searchBar}>
            <FilterFiles
              availableFilters={["title"]}
              simpleVerticalSearch={true}
            />
          </div>
          <div>
            <ListSelectionItems
              defaultQueryFn={listMediaFn}
              onCardClick={cardClickFn}
            />
          </div>
        </SearchWrapper>
        {/* </CollapsibleListSection> */}
      </div>
    )
  }

  return <>{getSearchMedia()}</>
}

AttachMedia.defaultProps = {
  allowedMediaTypes: ["Photo"],
}

AttachMedia.propTypes = {
  attachedMedia: PropTypes.array,
  onMediaAttached: PropTypes.func.isRequired,
}

export default AttachMedia
