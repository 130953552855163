import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import auth from "../../../../services/auth"
import mediaService from "../../../../services/media"
import storyService from "../../../../services/story"
import RenderSelectionItems from "../../../media-output/lists/render-selection-items/render-selection-items"
import { getGroup, updateGroups } from "../../../../services/group"

const RenderGroupMedia = ({
  attachedMedia,
  mediaType,
  onMediaAttached,
  group,
  groupMedia,
  id,
}) => {

  const [internalMedia, setInternalMedia] = useState(attachedMedia)
  const [media, setMedia] = useState([])
  const cardClickFn = data => {
    if (mediaType === "Photo") {
      mediaService.getMedia(data.id).then(result => {
        setInternalMedia(result)
        onMediaAttached(result)
      })
    } else {
      storyService.getStory(data.id).then(result => {
        setInternalMedia(result)
        onMediaAttached(result)
      })
    }
  }

  const onMediaAdd = files => {
    var addedMedias = []
    _.map(files, function(file) {
      addedMedias.push(file)
    })

    const NewMedias = _.union(NewMedias, addedMedias)
    console.log("NewMed:", NewMedias)
    setMedia(NewMedias)
  }

  const listMediaFn = () => {
    const authObj = auth.getAuth()
    return {
      query: {
        bool: {
          must: [
            {
              match: {
                grp: {
                  query: group,
                  operator: "and",
                },
              },
            },
            // {
            //   match: {
            //     branch: {
            //       query: authObj.user.branch.id,
            //       operator: "and",
            //     },
            //   },
            // },
            // {
            //   grp: {
            //     grp: {
            //       query: group,
            //       operator: "and",
            //     },
            //   },
            // },
          ],
        },
      },
    }
  }

  const getSearchMedia = () => {
    // return the search form
    // this markup is excessive. Just copying the existing stuff for the rest of the form

    return (
      <div>
        <div>
          <RenderSelectionItems
            idd={id}
            medias={media}
            groupMedia={groupMedia}
            handleAddFn={onMediaAdd}
            defaultQueryFn={listMediaFn}
            onCardClick={cardClickFn}
          />
        </div>
      </div>
    )
  }

  return <>{getSearchMedia()}</>
}

RenderGroupMedia.defaultProps = {
  allowedMediaTypes: ["Photo"],
}

RenderGroupMedia.propTypes = {
  attachedMedia: PropTypes.array,
  onMediaAttached: PropTypes.func.isRequired,
}

export default RenderGroupMedia
