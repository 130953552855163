import { ReactiveComponent } from "@appbaseio/reactivesearch"
import React from "react"
import moment from "moment"

function asSearchFilter(WrappedComponent, op) {
  let operator = op || "or"
  return ({ componentId, showFilter, filterLabel, ...props }) => {
    const setValue = (value, setQuery) => {
      if (isNullOrEmpty(value)) {
        setQuery({
          query: { match_all: {} },
          value: value,
        })
      } else {
        setValueQuery(value, setQuery)
      }
    }

    const isNullOrEmpty = value => {
      if (
        value === null ||
        value === undefined ||
        value.length === 0 ||
        value === ""
      ) {
        return true
      } else {
        return false
      }
    }

    const setValueQuery = (value, setQuery) => {
      if (props.fieldName === "multi") {
        setupMultiFieldValueQuery(value, setQuery)
      } else {
        setupSingleFieldValueQuery(value, setQuery)
      }
    }

    const parseValue = value => {
      var isArray = Array.isArray(value)
      var refVal = isArray ? value[0] : value

      if (refVal.source) {
        return isArray ? value.map(v => v.value).join(" ") : refVal.value
      } else {
        return isArray ? value.join(" ") : value
      }
    }

    const setupSingleFieldValueQuery = (value, setQuery) => {
      let query
      if (Array.isArray(value)) {
        query = {
          [props.queryType || "match"]: {
            [props.fieldName]: {
              query: parseValue(value),
              operator: operator,
              analyzer: "search",
            },
          },
        }
      } else if (value.startDate || value.endDate) {
        query = getDateRangeQuery(value)
      } else {
        query = {
          [props.queryType || "match"]: {
            [props.fieldName]: parseValue(value),
          },
        }
      }

      setQuery({
        query: query,
        value: value,
      })
    }

    const setupMultiFieldValueQuery = (value, setQuery) => {
      let queryFields = props.multiFieldNames.split(",")
      let query = {
        bool: {
          must: [
            {
              bool: {
                must: {
                  bool: {
                    should: [
                      {
                        multi_match: {
                          query: parseValue(value),
                          fields: queryFields,
                          type: "best_fields",
                          operator: "or",
                          fuzziness: 0,
                        },
                      },
                      {
                        multi_match: {
                          query: parseValue(value),
                          fields: queryFields,
                          type: "phrase",
                          operator: "or",
                        },
                      },
                    ],
                    minimum_should_match: "1",
                  },
                },
              },
            },
          ],
        },
      }

      let highlightOpt = {
        pre_tags: ["<mark>"],
        post_tags: ["</mark>"],
        number_of_fragments: 0,
        fields: {},
        //     pre_tags: ["<mark>"],
        //     post_tags: ["</mark>"],
        //     fields: { text: {}, title: {} },
        //     number_of_fragments: 0,
      }

      _.forEach(queryFields, fieldName => {
        highlightOpt[fieldName] = {}
      })

      setQuery({
        query: query,
        value: value,
        // options: {
          // preference: queryFields[0],
          // highlight: highlightOpt,
        // },
      })
    }

    const getDateRangeQuery = range => {
      let query = {
        range: {
          [props.fieldName]: getDateRangeInnerQuery(range),
        },
      }

      if (props.allowEmpty) {
        query = allowEmptyWrap(query)
      }

      return query
    }

    const getDateRangeInnerQuery = value => {
      let hasStart = value.startDate != null
      let hasEnd = value.endDate != null

      if (hasStart && hasEnd) {
        return {
          gte: moment(value.startDate).format("YYYYMMDD"),
          lte: moment(value.endDate).format("YYYYMMDD"),
        }
      } else if (hasStart) {
        return { gte: moment(value.startDate).format("YYYYMMDD") }
      } else if (hasEnd) {
        return { lte: moment(value.endDate).format("YYYYMMDD") }
      }
    }

    const allowEmptyWrap = q => ({
      bool: {
        should: [q, getDateRangeNullQuery()],
      },
    })

    const getDateRangeNullQuery = () => {
      return { bool: { must_not: { exists: { field: props.fieldName } } } }
    }

    return (
      <ReactiveComponent
        componentId={componentId}
        showFilter={showFilter}
        filterLabel={filterLabel}
        render={({ setQuery, value }) => (
          <WrappedComponent
            {...props}
            setQuery={setQuery}
            setValue={v => setValue(v, setQuery)}
            value={value}
            componentid={componentId}
          />
        )}
      />
    )
  }
}

export default asSearchFilter
