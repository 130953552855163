import { useState, useEffect } from "react"
import useDebounce from "./use-debounce"
import constants from "../services/constants_fhaa"

const useDebounceState = (value, setValue, delay) => {
  if ( !delay ) {
    delay = constants.searchDebounce
  }
  const [curValue, setCurValue] = useState(value)
  const debouncedValue = useDebounce(curValue, delay)
  useEffect(() => setValue(debouncedValue), [debouncedValue])
  useEffect(() => setCurValue(value), [value])
  return [curValue, setCurValue]
}

export default useDebounceState
