import { ReactiveList } from "@appbaseio/reactivesearch";
import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import StandardCard from "../../cards/standard-card/standard-card";
import styles from "./list-media.module.scss";
import Empty from "../../../common/empty/empty";
import util from "../../../../services/util";
import { FaTrashAlt, FaSpinner } from "react-icons/fa";
import "font-awesome/css/font-awesome.min.css";
import { getGroups } from "../../../../services/group";

const ListMedia = ({ onMediaNavigate, mixedMedia, onSelectKeyword, defaultQueryFn, setData, group, showTiles, fnSetLastRefreshDate }) => {
  const [loadingMarkers, setLoadingMarkers] = useState(true);
  const [groups, setGroups] = useState([]);

  const groupValue = Array.isArray(group) ? group[0] : group;
  console.log('groupValue:', groupValue);

  useEffect(() => {
    getGroups().then(group => {
      console.log('Group:', group);
      setGroups(group);
    });
  }, []);


  const groupMedia = groups.filter(item => item.title === groupValue);
  console.log('groupValueExists:', groupMedia);

  // Fix: Access the `media` property from the first matching group or use an empty array
  const filteredMedia = groupMedia.length > 0 ? groupMedia[0].media : [];
  console.log('filteredMediaaaaa:', filteredMedia);
  const data = mixedMedia;
  console.log('data:', data);
  useEffect(() => {
    if (filteredMedia.length > 0) {
      setData(filteredMedia);
    } else {
      setData(mixedMedia);
    }
  }, [filteredMedia, mixedMedia]);

  const renderStandardCards = (data) => {
    if (data.length > 0) {
      setLoadingMarkers(false);
    } else {
      setLoadingMarkers(true);
    }

    return (
      <div>
        {loadingMarkers &&
          <div>
            <FaSpinner className={classNames('fa-spin', styles.spinner)} />
          </div>
        }
        <br />
        <div className={data.length < 5 ? styles.cardLess : styles.cards}>
          {data.map(item => (
            <StandardCard
              key={item._id}
              data={util.fixUrls(item)}
              onMediaNavigate={onMediaNavigate}
              onSelectKeyword={onSelectKeyword}
              fnSetLastRefreshDate={fnSetLastRefreshDate}
              className={data.length < 5 ? styles.cardItem : ""}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <ReactiveList
        defaultQuery={defaultQueryFn}
        className={styles.wrap}
        componentId="SearchResult"
        dataField="name"
        title="ReactiveList"
        pagination={true}
        from={0}
        size={50}
        react={{
          and: [
            "searchKeywords",
            "searchTitle",
            "searchLocation",
            "searchType",
            "searchSource",
            "searchCopyright",
            "searchCaptureDate",
            "searchMulti",
            "searchDateUnknown",
            "searchPeople",
            "searchGroup",
          ],
        }}
        onData={(data) => {
          setData(data);
        }}
        showLoader={false}
        render={({ Loading, error, data }) => {
          if (Loading) {
            return <div>Fetching Result...</div>;
          }
          if (error) {
            return <div>Something went wrong! Error details {JSON.stringify(error)}</div>;
          }

          // Render filtered media if groupValue exists
          if (groupValue && groupMedia.length > 0) {
            return renderStandardCards(filteredMedia);
          } else {
            return renderStandardCards(data);
          }
        }}
        showResultStats={false}
        renderNoResults={() => {
          if (groupValue && groupMedia.length > 0) {
            return null;
          } else {
            return <Empty title="No results found" content="It seems we can’t find any results based on your search." />;
          }
        }}
      />
    </div>
  );
};

ListMedia.propTypes = {
  onMediaNavigate: PropTypes.func,
  onSelectKeyword: PropTypes.func,
  mixedMedia: PropTypes.array.isRequired,
  group: PropTypes.any,
  fnSetLastRefreshDate: PropTypes.func,
};

export default ListMedia;

