import { ReactiveList } from "@appbaseio/reactivesearch"
import PropTypes from "prop-types"
import React from "react"
import SelectionCard from "../../cards/selection-card/selection-card"
import "./list-selection-items.scss"
import Empty from "../../../common/empty/empty"
import util from "../../../../services/util"

// const baseUrl = process.env.GATSBY_FEDIA_DMS_PORT && process.env.GATSBY_FEDIA_DMS_PORT !== '80' && process.env.GATSBY_FEDIA_DMS_PORT !== 80 ? 
//   `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/` :
//   `http://${process.env.GATSBY_FEDIA_DMS_HOST}/`;

//   const fixUrls = media => {
//     return {
//       ...media,
//       webViewUrl: media.webViewUrl ? `${baseUrl}${media.webViewUrl.replace('http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/','')}` : media.webViewUrl,
//       thumbnailUrl: media.thumbnailUrl ? `${baseUrl}${media.thumbnailUrl.replace('http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/','')}` : media.thumbnailUrl
//     };
//   }

const ListSelectionItems = ({ onCardClick, defaultQueryFn }) => {
  const renderSelectionCard = data => {
    return (
      <div className={data.length < 4 ? "cardLess" : "cards"}>
        {data.map(item => (
          <SelectionCard
            key={item._id}
            data={util.fixUrls(item)}
            selectionClickFn={onCardClick}
            className={data.length < 4 ? "cardItem" : ""}
          />
        ))}
      </div>
    )
  }

  return (
    <>
      <div className="listSelectItems">
        <div className="container">
          <ReactiveList
            defaultQuery={defaultQueryFn}
            className="wrap"
            componentId="SearchResult"
            dataField="name"
            title="ReactiveList"
            stream={false}
            pagination={true}
            paginationAt="bottom"
            scrollOnChange={false}
            pages={5}
            size={10}
            react={{
              and: {
                and: [
                  "searchKeywords",
                  "searchTitle",
                  // "searchLocation",
                  // "searchType",
                  // "searchSource",
                  // "searchCopyright",
                  // "searchCaptureDate",
                  // "searchSpecies",
                  // "searchArchived",
                ],
              },
            }}
            showLoader={false}
            render={({ Loading, error, data }) => {
              if (Loading) {
                return <div>Fetching Result.</div>
              }
              if (error) {
                return (
                  <div>
                    Something went wrong! Error details {JSON.stringify(error)}
                  </div>
                )
              }
              return renderSelectionCard(data)
            }}
            showResultStats={false}
            renderNoResults={function() {
              return <Empty title="No results found" content="" />
            }}
          />
        </div>
      </div>
    </>
  )
}

ListSelectionItems.propTypes = {
  onCardClick: PropTypes.func,
}

export default ListSelectionItems
